<template>
  <div>
    <p class="text-green-500 mb-12">
      Afin de vous proposer l’offre la plus adaptée à vos besoins, merci de
      répondre aux questions ci-dessous :
    </p>

    <input-wrapper
      label="Vous êtes ?"
      required
      rules="required"
      v-if="hasManyTargets"
    >
      <choices-input
        v-model="form.type"
        :options="$cyberUp.targets"
      ></choices-input>
    </input-wrapper>

    <div class="max-w-screen-sm" v-if="form.type">
      <template v-if="$cyberUp.hasActivity(form.type)">
        <input-wrapper
          label-for="activity"
          label="Votre activité"
          required
          rules="required"
        >
          <input v-model="form.activity" id="activity" class="form-control" />
        </input-wrapper>
      </template>
      <template v-if="$cyberUp.hasActivity(form.type)">
        <input-wrapper
          label-for="employees"
          label="Votre effectif"
          required
          rules="required|numeric"
        >
          <number-input v-model="form.employees" id="employees"></number-input>
        </input-wrapper>
      </template>
      <template v-else>
        <input-wrapper
          label-for="inhabitants"
          label="Nombre d'habitants"
          required
          rules="required|numeric"
        >
          <number-input
            v-model="form.inhabitants"
            id="inhabitants"
          ></number-input>
        </input-wrapper>
      </template>
      <template v-if="$cyberUp.hasTurnover(form.type)">
        <input-wrapper
          label-for="turnover"
          label="Votre chiffre d'affaire"
          required
          rules="required|numeric"
          class="input-currency"
        >
          <number-input v-model="form.turnover" id="turnover"></number-input>
        </input-wrapper>
      </template>
      <template v-else>
        <input-wrapper
          label-for="budget"
          label="Budget de fonctionnement"
          required
          rules="required|numeric"
          class="input-currency"
        >
          <number-input v-model="form.budget" id="budget"></number-input>
        </input-wrapper>
      </template>
      <template v-if="$cyberUp.hasActivity(form.type)">
        <input-wrapper
          label="Faites-vous de la vente en ligne ?"
          required
          rules="required"
        >
          <choices-input
            v-model="form.onlineSales"
            :options="[
              { value: true, text: 'Oui' },
              { value: false, text: 'Non' },
            ]"
            class="input-online"
          ></choices-input>
        </input-wrapper>
        <input-wrapper
          label-for="onlineSalesTurnover"
          label="Votre chiffre d'affaire sur la vente en ligne"
          required
          rules="required|numeric"
          v-if="form.onlineSales"
          class="input-currency"
        >
          <number-input
            v-model="form.onlineSalesTurnover"
            id="onlineSalesTurnover"
          ></number-input>
        </input-wrapper>
      </template>
    </div>

    <div class="flex">
      <span class="block ml-auto">(*) champs obligatoires</span>
    </div>

    <slot name="actions"></slot>
  </div>
</template>

<script>
import Step from "@/mixins/wizard-step";
import InputWrapper from "@/components/base/InputWrapper.vue";
import NumberInput from "@/components/base/NumberInput.vue";
import ChoicesInput from "@/components/base/ChoicesInput.vue";

export default {
  mixins: [Step],
  components: {
    ChoicesInput,
    NumberInput,
    InputWrapper,
  },
  data: () => ({
    form: {
      type: null,
      activity: null,
      employees: null,
      turnover: null,
      onlineSales: null,
      onlineSalesTurnover: null,
      inhabitants: null,
      budget: null,
    },
  }),
  created() {
    /**
     * Préselection du type depuis l'URL
     */
    if (!this.hasManyTargets) {
      this.form.type = this.$cyberUp.targetLabel;
    }
  },
  computed: {
    hasManyTargets() {
      return this.$cyberUp.targets.length > 1;
    },
  },
};
</script>

<style lang="postcss" scoped>
.input-online {
  @apply flex;

  /deep/ > button {
    @apply flex-1;
  }
}
</style>
