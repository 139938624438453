<template>
  <div>
    <p class="text-green-500 mb-12">Vos coordonnées :</p>

    <div class="columns flex-wrap">
      <div class="column flex-none sm:w-1/2" v-if="legalFormLabel">
        <input-wrapper
          label-for="legalForm"
          :label="legalFormLabel"
          required
          rules="required"
        >
          <input
            v-model="form.legalForm"
            id="legalForm"
            class="form-control"
            :placeholder="legalFormPlaceholder"
          />
        </input-wrapper>
      </div>
      <div class="column flex-none sm:w-1/2">
        <input-wrapper
          label-for="name"
          :label="nameLabel"
          required
          rules="required"
        >
          <input v-model="form.name" id="name" class="form-control" />
        </input-wrapper>
      </div>
      <div class="column flex-none sm:w-1/2">
        <input-wrapper label-for="contact-name" label="Nom de l'interlocuteur">
          <input
            v-model="form.contactName"
            id="contact-name"
            class="form-control"
          />
        </input-wrapper>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-wrapper
          label-for="phone"
          label="Téléphone"
          required
          rules="required|phone|min:10|max:10"
        >
          <input
            v-model="form.phone"
            type="tel"
            id="phone"
            class="form-control"
          />
        </input-wrapper>
      </div>
      <div class="column">
        <input-wrapper
          label-for="email"
          label="Email"
          required
          rules="required|email"
        >
          <input
            v-model="form.email"
            type="email"
            id="email"
            class="form-control"
          />
        </input-wrapper>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-wrapper
          label-for="street"
          label="Adresse"
          required
          rules="required"
        >
          <input v-model="form.street" id="street" class="form-control" />
        </input-wrapper>
      </div>
      <div class="column">
        <input-wrapper
          label-for="city"
          label="Ville (CP)"
          required
          rules="required"
        >
          <place-input v-model="form.city" id="city" />
        </input-wrapper>
      </div>
    </div>

    <div class="flex">
      <span class="block ml-auto">(*) champs obligatoires</span>
    </div>

    <slot name="actions"></slot>
  </div>
</template>

<script>
import Step from "@/mixins/wizard-step";
import InputWrapper from "@/components/base/InputWrapper.vue";
import PlaceInput from "@/components/base/PlaceInput.vue";

export default {
  mixins: [Step],
  props: {
    type: String,
  },
  components: {
    PlaceInput,
    InputWrapper,
  },
  data: () => ({
    form: {
      legalForm: null,
      name: null,
      email: null,
      phone: null,
      street: null,
      city: null,
      contactName: null,
    },
  }),
  computed: {
    legalFormLabel() {
      if (this.type === "Entreprise") {
        return "Votre statut";
      }
      if (this.type === "Etablissement public") {
        return "Votre forme juridique";
      }
      return null;
    },
    legalFormPlaceholder() {
      if (this.type === "Entreprise") {
        return "Sarl, SAS, ...";
      }
      if (this.type === "Etablissement public") {
        return "SPL, Syndicat, ...";
      }
      return null;
    },
    nameLabel() {
      if (this.type === "Entreprise") {
        return "Nom de l’entreprise";
      }
      if (this.type === "Association" || this.type === "Etablissement public") {
        return "Nom de l’entité juridique";
      }
      return "Nom de la collectivité";
    },
  },
};
</script>

<style lang="postcss" scoped>
.column /deep/ .form-group {
  @screen lg {
    label {
      @apply block w-48 absolute;

      > span {
        top: 50%;
        transform: translateY(-50%);
        @apply absolute right-0 w-32;
      }
    }

    > div {
      @apply ml-48;
    }
  }
}
</style>
