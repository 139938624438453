<template>
  <div>
    <p class="text-green-500 mb-12">Sélectionner la formule qui convient...</p>
    <ValidationProvider name="Formule" rules="required" tag="div">
      <div class="flex flex-col md:flex-row mb-8">
        <div class="flex-1">
          <formula
            v-model="form.formula"
            type="Essentielle"
            bg-header="bg-green-500"
          >
            <div slot="header">Essentielle <sup>(1)</sup></div>
            <div>
              <ul class="features">
                <li>
                  <check></check>
                  <strong>Gestion de Crise</strong>
                </li>
                <li>
                  <check></check>
                  <strong>
                    Garanties « Frais et pertes subis par l’Assuré » dont :
                  </strong>
                  <ul>
                    <li>
                      Frais de reconstitution des données suite à un incident de
                      sécurité
                    </li>
                    <li>
                      Frais de décontamination de maliciel suite à un incident
                      de sécurité
                    </li>
                    <li>Pénalités suite à un incident de sécurité</li>
                    <li>
                      Frais supplémentaires d’exploitation suite à un incident
                      de sécurité (hors pertes d’exploitation)
                    </li>
                  </ul>
                </li>
                <li>
                  <check></check>
                  <strong>Garanties Cyber responsabilité dont :</strong>
                  <ul>
                    <li>
                      Responsabilité(s) de l’assurée encourue(s) suite à :
                      <ul class="text-sm list-disc ml-8 my-4">
                        <li>
                          Une atteinte aux données à caractère personnelles
                        </li>
                        <li>Un incident de sécurité</li>
                      </ul>
                    </li>
                    <li>
                      Atteinte à l’image ou à la propriété intellectuelle d’un
                      tiers
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </formula>
        </div>
        <div class="flex-1">
          <formula
            v-model="form.formula"
            type="Confort"
            bg-header="bg-green-700"
            class="recommended"
          >
            <div slot="header">
              <span class="badge">Formule conseillée</span>
              Confort <sup>(1)</sup>
            </div>

            <div>
              <ul class="features">
                <li>
                  <check></check>
                  <strong>Gestion de Crise</strong>
                </li>
                <li>
                  <check></check>
                  <strong>
                    Garanties « Frais et pertes subis par l’Assuré » dont :
                  </strong>
                  <ul>
                    <li>
                      Frais de reconstitution des données suite à un incident de
                      sécurité
                    </li>
                    <li>
                      Frais de décontamination de maliciel suite à un incident
                      de sécurité
                    </li>
                    <li>Pénalités suite à un incident de sécurité</li>
                    <li class="promote">Cyber Fraude</li>
                    <li class="promote">Piratage Téléphonique</li>
                    <li class="promote">Cyber Extorsion</li>
                    <li>
                      Frais supplémentaires d’exploitation suite à un incident
                      de sécurité (hors pertes d’exploitation)
                    </li>
                    <li class="promote">
                      Pertes d’Exploitation (y compris les frais supplémentaires
                      d’exploitation)
                    </li>
                  </ul>
                </li>
                <li>
                  <check></check>
                  <strong>Garanties Cyber responsabilité dont :</strong>
                  <ul>
                    <li>
                      Responsabilité(s) de l’assurée encourue(s) suite à :
                      <ul class="text-sm list-disc ml-8 my-4">
                        <li>
                          Une atteinte aux données à caractère personnelles
                        </li>
                        <li>Un incident de sécurité</li>
                      </ul>
                    </li>
                    <li>
                      Atteinte à l’image ou à la propriété intellectuelle d’un
                      tiers
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </formula>
        </div>
      </div>
    </ValidationProvider>

    <slot name="actions"></slot>

    <div class="mt-5 text-center">
      <captcha-info></captcha-info>
    </div>
  </div>
</template>

<script>
import Step from "@/mixins/wizard-step";
import Formula from "@/components/forms/cyber-up/cards/Formula.vue";
import Check from "@/assets/icons/check.svg";
import CaptchaInfo from "@/components/base/CaptchaInfo.vue";

export default {
  mixins: [Step],
  components: {
    Formula,
    Check,
    CaptchaInfo,
  },
  data: () => ({
    form: {
      formula: null,
    },
  }),
};
</script>

<style lang="postcss" scoped>
.card {
  @screen md {
    @apply mx-8;
  }

  @screen lg {
    @apply mx-16;
  }

  @screen xl {
    @apply mx-20;
  }
}

.badge {
  @apply absolute bg-orange-500 text-white p-2 text-xs rounded-full;
  top: -1rem;
  right: -1rem;
}

.features {
  @apply pl-8 relative;

  svg {
    @apply fill-green w-6 h-6 absolute left-0;
  }

  strong {
    @apply block mb-4;
  }

  > li {
    @apply mb-6;
  }

  ul li {
    @apply mb-2;
  }
}

.recommended {
  .promote {
    @apply font-bold text-green-500;
  }
}
</style>
