<template>
  <div class="card">
    <div class="card__header" :class="bgHeader">
      <slot name="header"></slot>
    </div>
    <div class="card__body">
      <slot></slot>

      <button
        type="button"
        class="btn-orange"
        @click="$emit('input', type)"
        :class="{ 'opacity-50': value !== null && value !== type }"
      >
        <span v-if="value === type">Sélectionnée</span>
        <span v-else>Sélectionner</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgHeader: String,
    type: String,
    value: String,
  },
};
</script>

<style lang="postcss" scoped>
.card {
  @apply h-full flex flex-col mb-8;

  p {
    @apply mb-4;
  }

  .card__header {
    @apply text-white uppercase font-bold text-center py-4 rounded-t-2xl relative;
  }

  .card__body {
    @apply flex flex-col bg-gray-100 border border-t-0 border-gray-300 h-full p-8 text-gray-900;

    .btn-orange {
      @apply mx-auto block h-12 text-lg mt-auto;
    }
  }
}
</style>
