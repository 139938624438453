<template>
  <ValidationObserver v-slot="{ invalid, failed }">
    <steps
      :steps="steps"
      :value="currentStep"
      @move-step="(i) => (i < currentStep || !invalid) && moveToStep(i)"
      class="hidden sm:flex mb-8"
    ></steps>

    <form @submit.prevent="onSubmit">
      <transition name="slide-fade" mode="out-in">
        <component
          :is="`step${currentStep}`"
          v-model="currentForm"
          :type="form[0].type"
          :result="result"
        >
          <div slot="actions" class="flex flex-col mt-5" v-if="!isEnd">
            <span
              v-if="errorMessage || failed"
              class="text-red-500 ml-auto mb-5 font-bold lg:w-1/3 text-right"
              >{{ errorMessage || "Merci de vérifier votre saisie." }}</span
            >
            <div class="flex">
              <button
                v-if="canPrev"
                type="button"
                class="btn-orange"
                @click="goPrev"
              >
                Précédent
              </button>
              <button
                v-if="canNext"
                class="btn-orange ml-auto"
                type="submit"
                :disabled="invalid || pending"
              >
                <loading-icon
                  v-if="pending"
                  class="animate-spin h-5 w-5"
                ></loading-icon>
                <span v-else>Suivant</span>
              </button>
            </div>
          </div>
        </component>
      </transition>
    </form>
  </ValidationObserver>
</template>

<script>
import WizardForm from "@/mixins/wizard-form";
import Steps from "@/components/base/Steps.vue";
import LoadingIcon from "@/assets/icons/loading.svg";

import Step1 from "@/components/forms/cyber-up/steps/Step1.vue";
import Step2 from "@/components/forms/cyber-up/steps/Step2.vue";
import Step3 from "@/components/forms/cyber-up/steps/Step3.vue";
import Step4 from "@/components/forms/cyber-up/steps/Step4.vue";

export default {
  name: "Form",
  mixins: [WizardForm],
  components: {
    Steps,
    Step1,
    Step2,
    Step3,
    Step4,
    LoadingIcon,
  },
  data: () => ({
    steps: [
      { title: "Vos informations", icon: "message" },
      { title: "Vos coordonnées", icon: "email" },
      { title: "Votre formule", icon: "settings" },
      { title: "Votre tarif", icon: "euro" },
    ],
    form: [
      {
        type: "",
        activity: "",
        employees: null,
        turnover: null,
        onlineSales: null,
        onlineSalesTurnover: null,
        inhabitants: null,
        budget: null,
      },
      {
        legalForm: "",
        name: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        contactName: "",
      },
      {
        formula: null,
      },
    ],
  }),
  mounted() {
    this.$cyberUp.push({ step: 1, title: "Vos informations" });
  },
  methods: {
    getStep1Data() {
      let data = {
        type_client: this.form[0].type,
      };

      if (this.$cyberUp.hasActivity(data.type_client)) {
        data = {
          ...data,
          activite: this.form[0].activity,
          employes: this.$cyberUp.getRange(this.form[0].employees, [10, 20]),
          vente_en_ligne: this.form[0].onlineSales ? "Oui" : "Non",
          vente_en_ligne_chiffre: this.$cyberUp.getRange(
            this.form[0].onlineSalesTurnover,
            [80000, 170000],
            (v) => `${v}€`
          ),
        };
      } else {
        data = {
          ...data,
          habitants: this.$cyberUp.getRange(this.form[0].inhabitants, [
            10000,
            100000,
          ]),
        };
      }

      if (this.$cyberUp.hasTurnover(data.type_client)) {
        data = {
          ...data,
          ca: this.$cyberUp.getRange(
            this.form[0].turnover,
            [80000, 170000],
            (v) => `${v}€`
          ),
        };
      } else {
        data = {
          ...data,
          budget: this.$cyberUp.getRange(
            this.form[0].budget,
            [80000, 170000],
            (v) => `${v}€`
          ),
        };
      }

      return data;
    },
    getStep2Data() {
      return {
        forme_juridique: this.form[1].legalForm,
        nom_entreprise: this.form[1].name,
      };
    },
    getPersonalData() {
      return {
        email: this.form[1].email,
        phone: this.form[1].phone,
      };
    },
    async onSubmit() {
      if (this.pending) {
        return;
      }
      this.pending = true;
      let data = {};

      switch (this.currentStep) {
        case 1:
          this.$cyberUp.push({
            step: 2,
            title: "Vos coordonnées",
            type: this.form[0].type,
            data: this.getStep1Data(),
          });

          this.goNext();
          break;
        case 2:
          this.$cyberUp.push({
            step: 3,
            title: "Votre formule",
            type: this.form[0].type,
            postCode: this.form[1].city,
            data: {
              ...this.getStep1Data(),
              ...this.getStep2Data(),
            },
            common: this.getPersonalData(),
          });

          this.goNext();
          break;
        case 3:
          /**
           * Validation serveur et récupération du tarif
           */
          data = {
            ...Object.assign({}, ...this.form),
          };

          if (window.grecaptcha) {
            data.captchaResponse = await window.grecaptcha.execute(
              "6Lf45NYZAAAAAEHKOUbU-GDaw5b-jMSgvUHKKd9e",
              {
                action: "submit",
              }
            );
          }

          try {
            this.result = await this.$http("POST", "email/cyber-up", data);

            /**
             * Tracking après validation
             */
            this.$cyberUp.push({
              step: 4,
              title: "Votre tarif",
              type: this.form[0].type,
              postCode: this.form[1].city,
              data: {
                ...this.getStep1Data(),
                ...this.getStep2Data(),
                formule: this.currentForm.formula,
              },
              common: this.getPersonalData(),
            });

            this.goNext();
          } catch (e) {
            this.errorMessage = e.message;
          } finally {
            this.pending = false;
          }
          break;
      }
    },
  },
};
</script>
